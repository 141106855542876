import { createStore } from 'vuex'
import { verifySSO } from '../api/authentication'
import VuexPersistence from 'vuex-persist'
import router from '../router'
import { authServer } from '../api'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
const user = vuexLocal.storage.getItem('user')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }
export default createStore({
  state: initialState,
  mutations: {
    loginSuccess (state, user) {
      state.status.loggedIn = true
      state.user = user
      vuexLocal.storage.setItem('user', user)
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout (state) {
      state.status.loggedIn = false
      state.user = null
      vuexLocal.storage.removeItem('user')
      authServer.post('/logout')
      router.push('/signin')
    }
  },
  actions: {
    verifySSO ({ commit }) {
      return verifySSO().then(
        data => {
          commit('loginSuccess', data.Data)
          window.location.replace('/')
          return Promise.resolve(data)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      router.push('/signin')
      commit('logout')
    }
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
