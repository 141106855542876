<template>
	<div>
		<page-title title="remote support" />
		<div class="card">
			<div class="card-body">
				<input placeholder="Query..." class="form-control" type="text" v-model="query" />

				<table class="table table-xl mt-3">
					<thead>
						<tr>
							<th width='25%'>Serial</th>
							<th width='25%'>Device type</th>
							<th width='25%'>Reference</th>
							<th width='25%'>Actions</th>
						</tr>
					</thead>
					<tbody>
						<remote-support-client
							v-for="client in clientsToRender"
							:key="client"
							:client="client"
						/>
						<tr>
							<h2 v-if="clients.length === 0">No support Clients available</h2>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import {
  getClients
} from '@/api/remote-support'
import PageTitle from '@/components/PageTitle.vue'
import RemoteSupportClient from '@/components/RemoteSupportClient.vue'

export default {
  name: 'RemoteSupport',
  data () {
    return { query: '', clients: [] }
  },
  components: {
    PageTitle,
    RemoteSupportClient
  },
  computed: {
    clientsToRender () {
      return this.clients.filter(client => client.includes(this.query))
    }
  },
  mounted () {
    getClients().then((response) => {
      if (response.data.Status !== 'ok') return
      this.clients = response.data.Data || []
    })
  }
}
</script>
<style>
.card-title {
	font-weight: bold !important;
}
</style>
