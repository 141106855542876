<template>
	<tr>
		<td width='25%'>{{client}}</td>
		<td width='25%'>{{deviceInfo.devicetype}}</td>
		<td width='25%'>{{deviceInfo.reference}}</td>
		<td width='25%'>
			<div class="btn-group">
				<button type="button" class="btn btn-success" @click.exact="connectToClient()"
					@click.shift="connectToClient(true)">
					Connect To Client
				</button>
				<button type="button" class="btn btn-warning" @click="disconnectClient()">Disable RC</button>
			</div>
			<form action="https://rc.skaarhoj.com/uapi/disablerc" :ref="client" method="POST"></form>
		</td>
	</tr>
</template>

<script>
import { mapState } from 'vuex'
import { getDeviceInfoBySerial } from '../api/devices'

export default {
  name: 'RemoteSupportCient',
  data () {
    return { deviceInfo: {} }
  },
  props: {
    client: String
  },
  computed: {
    ...mapState(['user'])
  },
  mounted () {
    getDeviceInfoBySerial(this.client).then(({ data: deviceInfo }) => {
      this.deviceInfo = deviceInfo
    })
  },
  methods: {
    async disconnectClient () {
      const result = await this.$swal.fire({
        icon: 'warning',
        title: 'Are you sure? You are about to disable remote control for: ' + this.client,
        showCancelButton: true
      })
      if (!result.isConfirmed) return
      document.cookie = 'deviceselect=' + this.client + ';path=/;domain=.skaarhoj.com'
      this.$refs[this.client].submit()
    },
    connectToClient (shift) {
      document.cookie = 'deviceselect=' + this.client + ';path=/;domain=.skaarhoj.com'
      if (!shift) {
        window.location.href = 'https://rc.skaarhoj.com'
      } else {
        window.open('https://rc.skaarhoj.com', '_blank')
      }
    }
  }
}
</script>
