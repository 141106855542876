<template>
<div>
	<page-title title="Serial Number Search" />

	<div class="card card-body">
		<input class="form-control" v-model="query" placeholder="Enter serial number or chipid..." ref="query" />
		<div>
		<input type="checkbox" style="margin-right: 4px;" class="form-input" v-model="showUnisketch" name="isUnisketch">
  <label class="mt-3" for="isUnisketch"><b>Include Unisketch</b></label></div>
		<h4>Models:</h4>
						<input style="width: 40%" placeholder="Enter model" @input="newSearch" v-model="filterModels" class="form-control" type="text">
		<table class="table table-xl mt-3" v-if="devices.length > 0">
			<thead>
				<tr>
					<th>Model</th>
					<th>S/N</th>
					<th>CID</th>
					<th>Opt</th>
					<th>Reference</th>
					<th>Comments</th>
					<th>License Mode</th>
					<th>Licenses</th>
					<th>Created</th>
					<th />
				</tr>
			</thead>
			<tbody>
				<device-row
					v-for="(device, index) in devices"
					:key="index"
					:device="device"
					:onDeviceDeleted="handleDeviceDeleted"
				/>
			</tbody>
		</table>
		<div class="d-flex justify-content-center" v-if="hasMoreItems">
			<button type="button" @click="nextPage" class="btn btn-primary">Load more</button>
		</div>
			<div class="mt-3" v-if="devices.length === 0 && query">
				<h3>We could not find any related items in Database</h3>
			</div>
	</div>
</div>
</template>

<script>
import _ from 'lodash'
import PageTitle from '../components/PageTitle.vue'
import { searchDevices } from '../api/devices'
import { api } from '@/api'
import DeviceRow from '../components/SerialNumberSearch/DeviceRow.vue'

export default {
  name: 'SerialNumberSearch',
  components: { PageTitle, DeviceRow },
  data () {
    return { query: '', page: 1, devices: [], hasMoreItems: false, models: [], filterModels: '', showUnisketch: false }
  },
  mounted () {
    this.$refs.query.focus()
    this.getModels()
    this.newSearch()
  },
  watch: {
    query () {
      this.newSearch()
    },
    showUnisketch () {
      this.newSearch()
    }
  },
  methods: {
    newSearch () {
      this.page = 1
      this.devices = []
      this.legacy_devices = []
      this.searchDevices(this.query)
    },
    getModels () {
      api.get('/device-models').then((response) => {
        this.models = response.data
      })
    },
    searchDevices: _.debounce(function () {
      searchDevices(this.query, this.page, this.filterModels).then(response => {
        if (!Array.isArray(response.data[0]) && !Array.isArray(response.data[1])) {
          this.hasMoreItems = false
          return
        }
        if (response.data[0]) {
          if (response.data[0].length < 10) this.hasMoreItems = false
          if (response.data[0].length === 10) this.hasMoreItems = true
          this.devices = this.devices.concat(response.data[0])
        } else {
          this.hasMoreItems = false
        }
        if (response.data[1] && this.showUnisketch) {
          for (const device of response.data[1]) {
            device.devicetype = device.SK_MODEL
            device.chipid = device.CID
            device.deviceoptions = device.options
          }
          this.devices = this.devices.concat(response.data[1])
        }
      })
    }, 400),
    nextPage: function () {
      this.page++
      this.searchDevices()
    },
    handleDeviceDeleted: function () {
      this.page = 1
      this.devices = []
      this.searchDevices()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
